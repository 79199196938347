@import './variables.scss';
@import './sizing.scss';

@keyframes loadingBar {
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: 300px 0;
  }
}

.c-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 189px);
  padding-top: $size-base * 3.5;
  margin: 0 auto;
  max-width: $max-md2;

  @media (max-width: $break-md) {
    padding-left: $size-base;
    padding-right: $size-base;
  }
}

.hide-md {
  @media (max-width: $break-md) {
    display: none;
  }
}

.c-generic-panel {
  border-radius: $common-radius;
  background-color: var(--white);
  margin-bottom: $size-page;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;

  &__header {
    border-radius: $common-radius $common-radius 0 0;
    background-color: var(--white);
    padding: $size-lg $size-base $size-lg $size-lg;
    display: flex;
    border-bottom: 1px solid var(--primary-gray);

    strong {
      font-size: $size-page;
      font-weight: 700;
      text-align: left;
    }
  }

  &__title {
    text-transform: capitalize;
    font-size: $size-lg;
    text-align: left;
    margin: 0 0 $size-lg;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .bt {
    margin: -$size-hf-page 0;
    border-radius: $tiny-radius;
    line-height: $size-page;
    font-size: $font-sm;
    font-weight: 500;
    text-align: center;
    color: var(--white);
    padding: $size-sm $size-base 1px;
    cursor: pointer;
    transition: all 0.2s linear;
    background-color: var(--primary-purple);

    &:hover {
      box-shadow: inset 0 0 0 99999px rgba(255, 255, 255, 0.2);
    }
  }
}

.c-btn {
  outline: none;
  border-radius: $common-radius;
  border: none;
  padding: $font-md #{$size-base * 0.5};
  font-size: $size-base;
  font-weight: 700;
  text-align: center;
  color: var(--white);
  cursor: pointer;
  transition: all 0.2s linear;
  background-color: var(--primary-purple);
  width: 100%;
  height: 50px;

  &:hover {
    background-color: var(--secondary-green);
  }
  &.disabled {
    cursor: default;
    opacity: 0.2;
  }

  &__secondary {
    border: 1px solid var(--primary-purple);
    background-color: transparent;
    color: var(--primary-purple);
    font-weight: normal;

    &:hover {
      background-color: transparent;
      border-color: var(--secondary-green);
      color: var(--secondary-green);
    }
  }
}

.c-fake-content {
  background-color: #f5f8fb;
  &__loading {
    border: 0 !important;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingBar;
    animation-timing-function: linear;
    background: #f5f8fb;
    background: linear-gradient(
      to right,
      #f3f3f3 8%,
      #f5f8fb 33%,
      #f3f3f3 56%,
      #f5f8fb 78%,
      #f3f3f3 88%
    );
    background-size: 600px;
  }
}

.u-capitalize {
  text-transform: capitalize;
}

.no-items-message {
  width: 270px;
  margin: auto;

  .no-items-icon {
    border-radius: 50%;
    background-color: #f8fcff;
    width: 100px;
    height: 100px;
    margin: auto;
    display: flex;

    img {
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  p {
    font-size: $font-md;
    line-height: 1.43;
    text-align: center;
    color: var(--gray-400);
  }
}

.no-items-message-full-page {
  width: 400px;
  margin: auto;

  .no-items-icon {
    border-radius: 50%;
    background-color: #eaf3fa;
    margin: auto;
    display: flex;
    width: 120px;
    height: 120px;

    img {
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  p {
    margin: 0;
    padding-top: 45px;
    font-size: $font-high;
    line-height: 1.67;
    text-align: center;
    color: var(--gray-400);
  }
}

.button-rounded {
  pointer-events: all;
  cursor: pointer;
  font-size: $font-md;
  line-height: $font-md;
  font-weight: 600;
  border-radius: $common-radius;
  padding: $size-hf-page 18px;
  height: 37px;
  background-color: var(--primary-purple);
  color: var(--white);
  transition: all 0.2s linear;
  text-decoration: none;
  min-width: 142px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.impersonate {
    width: 169px;
    background-color: var(--primary-blue) !important;
  }

  &.loading {
    background-color: var(--primary-blue) !important;
  }

  &.hungup {
    padding: $size-sm;
    background-color: var(--primary-red);
  }

  &:hover {
    background-color: var(--secondary-green);
  }
}

.toggle {
  display: flex;
  border-radius: $tiny-radius;
  padding: 2px;
  background: var(--blue-500);
  span {
    min-width: 90px;
    padding: 6px 14px;
    font-size: $font-md;
    color: var(--gray-400);
    text-align: center;

    &.active {
      background: var(--white);
      border-radius: $tiny-radius;
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.1);

      &:first-child {
        box-shadow: 2px 0 4px -1px rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        box-shadow: -2px 0 4px -1px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  &.tooltip--bottom {
    .tooltiptext {
      top: calc(100% + 10px);
      bottom: auto;
      &:after {
        top: -$size-page;
        border-color: transparent transparent var(--gray-900) transparent;
      }
    }
  }

  .tooltiptext {
    visibility: hidden;
    width: 180px;
    left: 50%;
    bottom: calc(100% + 10px);
    margin-left: -90px;
    background-color: var(--black);
    color: var(--white);
    text-align: center;
    padding: $size-base $size-md $size-sm;
    border-radius: $medium-radius;
    height: auto;

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    position: absolute;
    z-index: 1;

    color: var(--white);
    font-size: $font-md;

    span {
      font-size: $font-md;
    }

    &--small {
      width: 100px;
      margin-left: -50px;
    }

    &--md {
      width: 120px;
      margin-left: -60px;
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -$size-hf-page;
      border-width: $size-hf-page;
      border-style: solid;
      border-color: var(--black) transparent transparent transparent;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}
